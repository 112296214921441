<script setup lang="ts">
import type { PropType } from 'vue';

const props = defineProps({
  type: {
    type: String as PropType<
      'primary' | 'success' | 'info' | 'warning' | 'danger'
    >,
    default: 'default',
  },
  color: {
    type: String,
    default: '',
  },
  content: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
});
</script>

<template>
  <el-tag
    class="font-bold border-red-600"
    :type="props.type"
    :color="props.color"
    effect="dark"
  >
    <BaseIcon v-if="props.icon" :icon="props.icon" />
    {{ props.content }} <slot></slot
  ></el-tag>
</template>

<style>
.el-tag {
  border: none;
}
</style>
